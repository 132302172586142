<template>
  <div class="roleInfo">
    <div class="actionBar">
      <a-space>
        <CompanySelect
          @CompanySelect="CompanySelect"
          :serchKeyword="undefined"
          :selectId="listParams.company_id"
          style="width:200px"
        ></CompanySelect>

        <a-input-search
          v-model="listParams.keyword"
          placeholder="请输入关键词搜索"
          enter-button="查询"
          style="width: 350px"
          allowClear
          @search="onSearch"
        />
      </a-space>

      <a-button
        icon="plus"
        type="primary"
        @click="showModal"
        size="large"
        v-if="handLists.top.indexOf('add') != -1"
      >
        新增专题</a-button
      >
    </div>
    <a-modal v-model="PVWVisible" title="图片预览" :footer="null">
      <img
        :src="PVWImgUrl"
        style="maxWidth:100%;maxHeight:100%;margin:0 auto"
      />
    </a-modal>

    <a-modal
      :title="modalTitle"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确定"
      cancelText="取消"
    >
      <a-form
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
        :from="addFromData"
      >
        <a-form-item label="角色名称">
          <a-input v-model="addFromData.name" />
        </a-form-item>
        <a-form-item label="角色简介">
          <a-input v-model="addFromData.description" />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="{
        total: count,
        current: listParams.page,
        pageSize: listParams.limit,
        showTotal: (total) => `共 ${total} 条`,
      }"
      @change="pageChange"
    >
      <span slot="index" slot-scope="text, record, index">
        {{ index }}
      </span>

      <span slot="img_url" slot-scope="text">
        <a v-if="text">
          <img
            @click="pvwShow(text)"
            :src="text"
            alt="图片地址发生错误"
            style="max-width: 100px;max-height:100px"
          />
        </a>
        <span v-else>
          暂无
        </span>
      </span>

      <span slot="status" slot-scope="text, record">
        <span v-if="handLists.right.indexOf('setStatus') != -1">
          <a-popover placement="topRight">
            <template slot="content">
              {{ text == 1 ? "点击下线" : "点击上线" }}
            </template>
            <a-tag
              :color="text == 1 ? 'green' : '#999'"
              @click="AsetStatus(record.id, text == 1 ? 2 : 1)"
            >
              {{ text == 1 ? "已上线" : "下线" }}
            </a-tag>
          </a-popover>
          <a-popover placement="topLeft">
            <template slot="content">
              {{ record.recommended == 1 ? "点击取消推荐" : "点击推荐" }}
            </template>
            <a-tag
              :color="record.recommended == 1 ? 'green' : '#999'"
              @click="AsetStatus(record.id, record.recommended == 1 ? 4 : 3)"
            >
              {{ record.recommended == 1 ? "已推荐" : "未推荐" }}
            </a-tag>
          </a-popover>
        </span>
        <span v-else>
          <a-tag :color="text == 1 ? 'green' : '#999'">
            {{ text == 1 ? "已上线" : "下线" }}
          </a-tag>
          <a-tag :color="record.recommended == 1 ? 'green' : '#999'">
            {{ record.recommended == 1 ? "已推荐" : "未推荐" }}
          </a-tag>
        </span>
      </span>

      <span slot="action" slot-scope="text, record">
        <!-- <a-icon title="修改" class="hIcon" type="edit" @click="editMenu(record)" v-if="record.published_time == 0" /> -->
        <span
          v-if="
            record.published_time == 0 &&
              handLists.right.indexOf('publish') != -1
          "
        >
          <a-popconfirm
            title="发布之后，不可修改！"
            ok-text="确定"
            cancel-text="取消"
            @confirm="confirm(record)"
          >
            <FontIcon title="发布" type="icon-fabu" size="22"></FontIcon>
          </a-popconfirm>
          <a-divider type="vertical" />
        </span>

        <FontIcon
          @click.native="editMenu(record)"
          title="修改"
          type="icon-xiugai"
          size="22"
          v-if="
            record.published_time == 0 && handLists.right.indexOf('edit') != -1
          "
        ></FontIcon>
        <FontIcon
          title="查看详情"
          type="icon-chakan"
          size="22"
          @click.native="chakan(record)"
          v-else
        ></FontIcon>
        <span v-if="handLists.right.indexOf('del') != -1">
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定删除？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="Sdelete(record.id)"
          >
            <FontIcon title="删除" type="icon-lajitong" size="22"></FontIcon>
          </a-popconfirm>
        </span>
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  specialIndex,
  specialSetStatus,
  specialDataSave,
  specialDataPublish,
  specialDelete,
} from "@/apis/index";
import moment from "moment";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
    align: "center",
  },
  {
    title: "专题名称",
    dataIndex: "title",
    scopedSlots: { customRender: "title" },
  },
  {
    title: "关联企业",
    dataIndex: "company",
    scopedSlots: { customRender: "company" },
    customRender: (text, record) => {
      return text.company_name;
    },
  },
  {
    title: "封面图",
    dataIndex: "img_url",
    scopedSlots: { customRender: "img_url" },
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    scopedSlots: { customRender: "create_time" },
    customRender: (text, record) => {
      if (text > 0) {
        return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "";
      }
    },
  },
  {
    title: "设置状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
    width: 160,
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: 140,
  },
];

export default {
  data() {
    return {
      columns,
      data: [],
      visible: false,
      confirmLoading: false,
      modalTitle: "",
      addFromData: {},
      listParams: {
        page: 1,
        limit: 10,
        keyword: "",
        company_id: undefined,
      },
      count: 0,
      PVWVisible: false,
      PVWImgUrl: "",

      handLists: this.$store.state.menuHandleList,
    };
  },
   computed: {
    handList() {
      return this.$store.state.menuHandleList;
    },
  },
  watch: {
    handList(val) {
      this.handLists.top = val.top ? val.top : [];
      this.handLists.right = val.right ? val.right : [];
    },
  },
  created() {
    this.getSpecialIndex();
  },
  methods: {
    pvwShow(text) {
      this.PVWVisible = true;
      this.PVWImgUrl = text;
    },
    Sdelete(id) {
      specialDelete({ id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getSpecialIndex();
        }
      });
    },

    AsetStatus(id, val) {
      specialSetStatus({
        ids: id,
        status: val,
      }).then((res) => {
        if (res.code == 1) {
          this.getSpecialIndex();
          this.$message.success("状态修改成功");
        }
      });
    },
    showModal() {
      this.$router.push({ path: "/contents/AddEditSpecial" });
    },
    handleOk(e) {
      this.confirmLoading = true;
      specialDataSave(this.addFromData).then((res) => {
        this.getSpecialIndex();
        this.visible = false;
        this.confirmLoading = false;
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
    editMenu(record) {
      this.$router.push({
        path: "/contents/AddEditSpecial",
        query: { id: record.id },
      });
    },
    chakan(record) {
      this.$router.push({
        path: "/contents/AddEditSpecial",
        query: { id: record.id, look: 1 },
      });
    },
    confirm(rec) {
      specialDataPublish({ id: rec.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("发布成功");
          this.getSpecialIndex();
        }
      });
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getSpecialIndex();
    },
    CompanySelect(val) {
      this.listParams.company_id = val;
      this.getSpecialIndex();
    },

    getSpecialIndex() {
      specialIndex(this.listParams).then((res) => {
        if (res.data.list.length == 0 && this.listParams.page > 1) {
          this.listParams.page--;
          this.getSpecialIndex();
        } else {
          this.data = res.data.list;
          this.count = res.data.count;
        }
      });
    },
    onSearch(value) {
      this.listParams.page = 1;
      this.getSpecialIndex();
    },
  },
};
</script>

<style lang="less" scoped>
.roleInfo {
  .actionBar {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }
}
</style>
