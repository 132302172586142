var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"roleInfo"},[_c('div',{staticClass:"actionBar"},[_c('a-space',[_c('CompanySelect',{staticStyle:{"width":"200px"},attrs:{"serchKeyword":undefined,"selectId":_vm.listParams.company_id},on:{"CompanySelect":_vm.CompanySelect}}),_c('a-input-search',{staticStyle:{"width":"350px"},attrs:{"placeholder":"请输入关键词搜索","enter-button":"查询","allowClear":""},on:{"search":_vm.onSearch},model:{value:(_vm.listParams.keyword),callback:function ($$v) {_vm.$set(_vm.listParams, "keyword", $$v)},expression:"listParams.keyword"}})],1),(_vm.handLists.top.indexOf('add') != -1)?_c('a-button',{attrs:{"icon":"plus","type":"primary","size":"large"},on:{"click":_vm.showModal}},[_vm._v(" 新增专题")]):_vm._e()],1),_c('a-modal',{attrs:{"title":"图片预览","footer":null},model:{value:(_vm.PVWVisible),callback:function ($$v) {_vm.PVWVisible=$$v},expression:"PVWVisible"}},[_c('img',{staticStyle:{"maxWidth":"100%","maxHeight":"100%","margin":"0 auto"},attrs:{"src":_vm.PVWImgUrl}})]),_c('a-modal',{attrs:{"title":_vm.modalTitle,"visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"okText":"确定","cancelText":"取消"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"label-col":{ span: 5 },"wrapper-col":{ span: 12 },"from":_vm.addFromData}},[_c('a-form-item',{attrs:{"label":"角色名称"}},[_c('a-input',{model:{value:(_vm.addFromData.name),callback:function ($$v) {_vm.$set(_vm.addFromData, "name", $$v)},expression:"addFromData.name"}})],1),_c('a-form-item',{attrs:{"label":"角色简介"}},[_c('a-input',{model:{value:(_vm.addFromData.description),callback:function ($$v) {_vm.$set(_vm.addFromData, "description", $$v)},expression:"addFromData.description"}})],1)],1)],1),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data,"rowKey":"id","pagination":{
      total: _vm.count,
      current: _vm.listParams.page,
      pageSize: _vm.listParams.limit,
      showTotal: function (total) { return ("共 " + total + " 条"); },
    }},on:{"change":_vm.pageChange},scopedSlots:_vm._u([{key:"index",fn:function(text, record, index){return _c('span',{},[_vm._v(" "+_vm._s(index)+" ")])}},{key:"img_url",fn:function(text){return _c('span',{},[(text)?_c('a',[_c('img',{staticStyle:{"max-width":"100px","max-height":"100px"},attrs:{"src":text,"alt":"图片地址发生错误"},on:{"click":function($event){return _vm.pvwShow(text)}}})]):_c('span',[_vm._v(" 暂无 ")])])}},{key:"status",fn:function(text, record){return _c('span',{},[(_vm.handLists.right.indexOf('setStatus') != -1)?_c('span',[_c('a-popover',{attrs:{"placement":"topRight"}},[_c('template',{slot:"content"},[_vm._v(" "+_vm._s(text == 1 ? "点击下线" : "点击上线")+" ")]),_c('a-tag',{attrs:{"color":text == 1 ? 'green' : '#999'},on:{"click":function($event){return _vm.AsetStatus(record.id, text == 1 ? 2 : 1)}}},[_vm._v(" "+_vm._s(text == 1 ? "已上线" : "下线")+" ")])],2),_c('a-popover',{attrs:{"placement":"topLeft"}},[_c('template',{slot:"content"},[_vm._v(" "+_vm._s(record.recommended == 1 ? "点击取消推荐" : "点击推荐")+" ")]),_c('a-tag',{attrs:{"color":record.recommended == 1 ? 'green' : '#999'},on:{"click":function($event){return _vm.AsetStatus(record.id, record.recommended == 1 ? 4 : 3)}}},[_vm._v(" "+_vm._s(record.recommended == 1 ? "已推荐" : "未推荐")+" ")])],2)],1):_c('span',[_c('a-tag',{attrs:{"color":text == 1 ? 'green' : '#999'}},[_vm._v(" "+_vm._s(text == 1 ? "已上线" : "下线")+" ")]),_c('a-tag',{attrs:{"color":record.recommended == 1 ? 'green' : '#999'}},[_vm._v(" "+_vm._s(record.recommended == 1 ? "已推荐" : "未推荐")+" ")])],1)])}},{key:"action",fn:function(text, record){return _c('span',{},[(
          record.published_time == 0 &&
            _vm.handLists.right.indexOf('publish') != -1
        )?_c('span',[_c('a-popconfirm',{attrs:{"title":"发布之后，不可修改！","ok-text":"确定","cancel-text":"取消"},on:{"confirm":function($event){return _vm.confirm(record)}}},[_c('FontIcon',{attrs:{"title":"发布","type":"icon-fabu","size":"22"}})],1),_c('a-divider',{attrs:{"type":"vertical"}})],1):_vm._e(),(
          record.published_time == 0 && _vm.handLists.right.indexOf('edit') != -1
        )?_c('FontIcon',{attrs:{"title":"修改","type":"icon-xiugai","size":"22"},nativeOn:{"click":function($event){return _vm.editMenu(record)}}}):_c('FontIcon',{attrs:{"title":"查看详情","type":"icon-chakan","size":"22"},nativeOn:{"click":function($event){return _vm.chakan(record)}}}),(_vm.handLists.right.indexOf('del') != -1)?_c('span',[_c('a-divider',{attrs:{"type":"vertical"}}),_c('a-popconfirm',{attrs:{"title":"确定删除？","ok-text":"确定","cancel-text":"取消"},on:{"confirm":function($event){return _vm.Sdelete(record.id)}}},[_c('FontIcon',{attrs:{"title":"删除","type":"icon-lajitong","size":"22"}})],1)],1):_vm._e()],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }